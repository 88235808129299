import { useFormik } from "formik";
import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "../Modals/Modal";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";
import { useNavigate } from "react-router-dom";
import { CssTextField } from "../../static/consts";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";

const SignInForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email(t("valid_email"))
      .required(t("email_required")),
    password: yup.string(t("valid_password")).required(t("password_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      const data = await CallService.SignIn(values);
      if (data.user) {
        if (data.user.firstName !== null) {
          localStorage.setItem("firstName", data.user.firstName);
        }

        localStorage.setItem("id", data.user.id);
        localStorage.setItem("token", data.accessToken);
        navigate("/");
      } else {
        setErrorMessage(data);
      }
    },
  });

  return (
    <div className="w-4/6 mx-auto mb-8">
      <h1 className="text-5xl mt-8 font-bold text-green h-24 lg:h-44">
        {t("sign_in")}
      </h1>

      <form onSubmit={formik.handleSubmit} className="flex-col">
        <div className="h-16">
          <CssTextField
            id="email"
            name="email"
            placeholder={t("enter_email")}
            variant="standard"
            className="w-full lg:w-11/12 2xl:w-10/12"
            style={{
              outline: "none",
              paddingBottom: 12,
              marginBottom: 8,
              color: "#191D43",
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>

        <div className="h-16">
          <CssTextField
            id="password"
            name="password"
            type="password"
            className="w-full lg:w-11/12 2xl:w-10/12"
            placeholder={t("enter_password")}
            variant="standard"
            style={{
              outline: "none",
              color: "#191D43",
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>

        <p className={errorMessage ? "pb-4 text-red" : "hidden"}>
          {t("user_incorrect_data")}
        </p>

        <Button
          variant="contained"
          type="submit"
          className="w-full lg:w-3/12"
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#191D43",
            fontSize: "16px",
            textTransform: "none",
            color: "#FFF",
            fontWeight: "600",
          }}
        >
          {t("sign_in")}
        </Button>

        <p className="hidden lg:inline-block mx-4 text-gray">{t("or")}</p>

        <button
          onClick={openModal}
          className="block lg:hidden mt-4 mb-8 font-bold text-blue"
        >
          {t("forgot_password")}
        </button>

        <Button
          variant="contained"
          className="w-full lg:w-6/12"
          onClick={() => navigate("/registration")}
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#EDEDF4",
            fontSize: "16px",
            textTransform: "none",
            color: "#191D43",
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {t("registration")}
        </Button>
      </form>

      <button
        onClick={openModal}
        className="hidden lg:block my-8 font-bold text-blue"
      >
        {t("forgot_password")}
      </button>

      <Modal show={isModalOpen} onClose={closeModal}>
        <ForgotPasswordModal onClick={closeModal} />
      </Modal>
    </div>
  );
};

export default SignInForm;
