import React from "react";
import { useTranslation } from "react-i18next";
import GroupHome from "../../static/landing-images/Main.svg";
import ButtonLink from "./LandingButton/ButtonLink";

const LandingMain = () => {
  const { t } = useTranslation();

  return (
    <div className="flex sm:justify-between items-center sm:flex-row flex-col-reverse  mt-8">
      <div className="sm:w-7/12 w-full flex flex-col justify-center">
        <h2 className="font-bold sm:text-5xl sm:text-left text-2xl text-center sm:leading-tight">
          {t("landing__main")}
        </h2>
        <p className="sm:my-8 my-3 sm:text-left text-center sm:text-lg text-sm font-normal">
          {t("islamInvest_small")}
        </p>
        <ButtonLink link="/login">{t("start_investing")}</ButtonLink>
      </div>
      <div className="sm:w-auto w-8/12 mb-4 sm:mb-0">
        <img src={GroupHome} alt="" />
      </div>
    </div>
  );
};

export default LandingMain;
