import { useFormik } from "formik";
import { Button } from "@mui/material";
import { CssTextField } from "../../static/consts";
import SuccessModal from "../Modals/SuccessModal";
import { useState, useEffect } from "react";
import Modal from "../Modals/Modal";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CallService from "../../services/CallService";
import { useNavigate } from "react-router-dom";

const UserForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);
  const [user, setUser] = useState(null);
  const [userPhone, setUserPhone] = useState("");
  const phonePattern =
    /^(?:[0-9]{0,3})(?:(?:(?=\d{2,3}))?(\d{2,3})(?:(?!\d{3}))?)(?:(?:(?=\d{3}))?(\d{3})(?:(?!\d{3}))?)(?:(?:(?=\d{4}))?(\d{4}))$/;
  const navigate = useNavigate();

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const handleChange = (e) => {
    setIsSubmitAllowed(true);
    formik.handleChange(e);
  };

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const data = await CallService.GetUserInfo();
      setUser(data);

      if (data.phone) {
        setUserPhone(data.phone);
      }
    })();
  }, []);

  const validationSchema = yup.object({
    firstName: yup
      .string(t("enter_first_name"))
      .required(t("required_field"))
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("latin_first_name")
      ),
    lastName: yup
      .string(t("enter_last_name"))
      .required(t("required_field"))
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("latin_last_name")
      ),
    phone: yup.string(t("enter_phone")).required(t("required_field")),
    telegram: yup.string(t("enter_telegram")),
  });

  const email = localStorage.getItem("email");

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.phone || "",
      email: email || "",
      telegram: user?.telegram || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!phonePattern.test(userPhone)) {
        return;
      }

      const { id } = localStorage;
      const data = await CallService.UpdateUser(id, {
        ...values,
        phone: userPhone,
      });

      await CallService.CreateUserCrm({
        ...values,
        email,
        phone: userPhone,
      });

      if (data) {
        localStorage.setItem("firstName", values.firstName);
        setIsModalOpen(true);
      }
    },
  });

  return (
    <div className="bg-white rounded-xl font-bold text-blue mb-12 md:mb-20 xl:mb-32 shadow mx-auto">
      <h2 className="p-4 text-lg">{t("personal_data")}</h2>
      <form onSubmit={formik.handleSubmit}>
        <CssTextField
          fullWidth
          id="firstName"
          name="firstName"
          placeholder={t("first_name")}
          style={{
            marginBottom: 12,
            fontSize: "16px",
            color: "#191D43",
            width: "90%",
          }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          value={formik.values.firstName}
          onChange={handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <CssTextField
          fullWidth
          id="lastName"
          name="lastName"
          placeholder={t("last_name")}
          type="text"
          style={{
            marginBottom: 12,
            width: "90%",
          }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          value={formik.values.lastName}
          onChange={handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <PhoneInput
          inputProps={{
            id: "phone",
            name: "phone",
            required: true,
          }}
          enableSearch
          enableTerritories
          placeholder={t("phone")}
          value={userPhone}
          onChange={(value, country, e) => {
            setUserPhone(value);
            handleChange(e);
          }}
          isValid={(value, country) => {
            if (!value.match(phonePattern)) {
              return t("valid_phone");
            }
            return true;
          }}
        />
        <CssTextField
          fullWidth
          id="telegram"
          name="telegram"
          placeholder={t("telegram")}
          type="text"
          style={{
            marginBottom: 12,
            width: "90%",
          }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          value={formik.values.telegram}
          onChange={handleChange}
          error={formik.touched.telegram && Boolean(formik.errors.telegram)}
          helperText={formik.touched.telegram && formik.errors.telegram}
        />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="btn-gradient btn-opacity"
          disabled={!isSubmitAllowed}
          style={{
            borderRadius: 8,
            height: 50,
            width: "90%",
            backgroundColor: "#4BCBC8",
            fontSize: "16px",
            textTransform: "none",
            marginBottom: 20,
          }}
        >
          {t("save")}
        </Button>
      </form>
      <Modal show={isModalOpen} onClose={closeModal}>
        <SuccessModal onClick={closeModal} text={t("success_data_update")} />
      </Modal>
    </div>
  );
};

export default UserForm;
