import { useFormik } from "formik";
import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";

const SignUpForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email(t("valid_email"))
      .matches(emailPattern, "Email is not valid")
      .required(t("email_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = await CallService.SignUp({
        ...values,
        email: values.email.toLowerCase(),
      });

      if (data.user) {
        localStorage.setItem("id", data.user.id);
        localStorage.setItem("email", values.email.toLowerCase());
        localStorage.setItem("token", data.accessToken);
        navigate("/profile");
      } else if (data.statusCode === 400) {
        setErrorMessage(data.message);
      }
    },
  });

  return (
    <div className="w-4/6 mx-auto mb-8 lg:mb-24">
      <h1 className="text-4xl lg:text-5xl font-bold text-green my-8 lg:h-44 lg:mb-8">
        {t("sign_up")}
      </h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="h-20 border-0">
          <TextField
            id="email"
            name="email"
            placeholder={t("enter_email")}
            variant="standard"
            className="w-full lg:w-11/12 2xl:w-10/12"
            style={{
              outline: "none",
              color: "#191D43",
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>

        <p className={errorMessage ? "text-red pb-2" : "hidden"}>
          {t("exist_user")}
        </p>

        <Button
          variant="contained"
          type="submit"
          className="w-full lg:w-6/12"
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#191D43",
            fontSize: "16px",
            textTransform: "none",
            color: "#FFF",
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {t("registration")}
        </Button>

        <span className="hidden lg:inline-block mx-4 text-gray">{t("or")}</span>
        <p className="h-8 lg:hidden"></p>

        <Button
          variant="contained"
          className="w-full lg:w-3/12"
          onClick={() => navigate("/login")}
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#EDEDF4",
            fontSize: "16px",
            textTransform: "none",
            color: "#191D43",
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {t("sign_in")}
        </Button>
      </form>
    </div>
  );
};

export default SignUpForm;
