import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../static/images/islaminvest.png";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

const LandigHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-5 flex justify-between">
      <div className="flex items-center">
        <img src={Logo} alt="Logo" />
        <div className="ml-6">
          <LanguageSelect />
        </div>
      </div>
      <div className="flex items-center">
        <Link
          to="/login"
          className="font-bold sm:text-lg text-sm px-4 py-2 border sm:border-0 rounded-lg"
        >
          {t("sign_in")}
        </Link>
        <div className="w-0.5 h-6 sm:flex hidden bg-selectGray mx-5"></div>
        <Link
          to="/login"
          className="border sm:flex hidden px-4 py-2 rounded-lg font-bold text-lg hover:bg-black hover:text-white transition-all"
        >
          {t("sign_up")}
        </Link>
      </div>
    </div>
  );
};

export default LandigHeader;
