import vector from "../../static/images/Vector1.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import slide1 from "../../static/images/Frame1.png";
import slide2 from "../../static/images/Frame2.png";
import slide3 from "../../static/images/Frame3.png";
import { useEffect, useState } from "react";
import CallService from "../../services/CallService";

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <img
      src={vector}
      alt="vector"
      className={className}
      onClick={onClick}
      style={{ transform: "rotate(180deg)" }}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <img src={vector} alt="vector" className={className} onClick={onClick} />
  );
};

const LoginSlider = () => {
  const [data, setData] = useState(0);
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    (async () => {
      const { amount } = await CallService.GetLoginData();
      amount && setData(amount);
    })();
  }, []);

  return (
    <Slider
      {...settings}
      className="sm:w-1/2 xl:w-10/12 lg:w-10/12 text-center mx-auto w-4/5 lg:mt-12 lg:pb-12 mb-12 mt-10"
    >
      <div className="">
        <img
          src={slide1}
          alt="SLide 1"
          className="w-10/12 mb-8 lg:w-11/12 xl:w-10/12 2xl:w-9/12 lg:mb-16 mx-auto h-auto"
        />
        <h2 className="text-3xl	font-extrabold text-green">
          $ {data.toLocaleString()}
        </h2>
        <p className="text-base font-medium text-blue mb-2">
          Total Value Staked
        </p>
      </div>
      <div className="text-center">
        <img
          src={slide2}
          alt="SLide 1"
          className="w-10/12 mb-8 lg:w-11/12 xl:w-10/12 2xl:w-9/12 lg:mb-16 mx-auto h-auto lg:h-72"
        />
        <h2 className="text-3xl	font-extrabold text-green">
          $ {data.toLocaleString()}
        </h2>
        <p className="text-base font-medium text-blue">Total Value Staked</p>
      </div>
      <div className="text-center">
        <img
          src={slide3}
          alt="SLide 1"
          className="w-10/12 mb-8 lg:w-11/12 xl:w-10/12 2xl:w-9/12 lg:mb-16 mx-auto h-auto lg:h-72"
        />
        <h2 className="text-3xl	font-extrabold text-green">
          $ {data.toLocaleString()}
        </h2>
        <p className="text-base font-medium text-blue">Total Value Staked</p>
      </div>
    </Slider>
  );
};

export default LoginSlider;
