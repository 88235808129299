import React from "react";
import Logo from "../../static/images/islaminvest.png";
import Instagram from "../../static/landing-images/social/instagram.svg";
import Telegram from "../../static/landing-images/social/telegram.svg";

const LandingFooter = () => {
  return (
    <div className="bg-grayBg sm:mt-32 mt-6">
      <div className="max-w-7xl mx-auto lg:px-0 px-5 sm:py-16 py-5">
        <div className="flex flex-col items-center sm:items-start flex-wrap ">
          <div>
            <img src={Logo} alt="" />
          </div>
          <div className="w-full flex items-center flex-wrap justify-center sm:justify-between sm:mt-14 mt-2 sm:ml-0 ml-4">
            <span className="sm:text-xl text-base">
              ©2022 islaminvest. All rights reserved
            </span>

            <div className="flex gap-3 mt-3 sm:mt-0">
              <a
                className="flex items-center"
                href="https://t.me/islaminvestcrypto"
              >
                <img className="w-8 h-8  mt-0.5 " src={Telegram} />
                <span className="ml-2">Telegram</span>
              </a>
              <a
                className="flex items-center"
                href="https://www.instagram.com/islaminvest.io"
              >
                <img className="w-9 h-9 " src={Instagram} />
                <span className="ml-2">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
