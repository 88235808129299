import Header from "../Header/Header";
import { Navigate } from "react-router-dom";
import Box from '@mui/material/Box';

const MainLayout = ({ children }) => {
  const { token } = localStorage;

  return (
    <>
      {token ? (
        <div className="bg-main min-h-screen">
          <Header />
          <Box className="w-10/12 mx-auto" sx={{ paddingTop: { xs: 12, sm: 17 } }}>{children}</Box>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default MainLayout;
